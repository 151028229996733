var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      "data": _vm.tableData.data,
      "height": "50vh"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Number",
      "prop": "number"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Message",
      "prop": "message"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Sender ID",
      "prop": "sender_id"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "label": "Status",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status.toUpperCase() == 'DELIVERED' ? _c('p', {
          staticStyle: {
            "color": "#36d962",
            "margin": "0 auto"
          }
        }, [_vm._v(" DELIVERED ")]) : scope.row.status.toUpperCase() == 'NOT_DELIVERED' ? _c('p', {
          staticStyle: {
            "color": "#000",
            "margin": "0 auto"
          }
        }, [_vm._v(" NOT DELIVERED ")]) : scope.row.status.toUpperCase() == 'SUBMITTED' ? _c('p', {
          staticStyle: {
            "color": "#30a1f0",
            "margin": "0 auto"
          }
        }, [_vm._v(" SUBMITTED ")]) : scope.row.status.toUpperCase() == 'FAILED' ? _c('p', {
          staticStyle: {
            "color": "#f0c630",
            "margin": "0 auto"
          }
        }, [_vm._v(" ACCEPTED ")]) : scope.row.status.toUpperCase() == 'EXPIRED' ? _c('p', {
          staticStyle: {
            "color": "#f7921c",
            "margin": "0 auto"
          }
        }, [_vm._v(" EXPIRED ")]) : scope.row.status.toUpperCase() == 'REJECTED' ? _c('p', {
          staticStyle: {
            "color": "#bc76ba",
            "margin": "0 auto"
          }
        }, [_vm._v(" REJECTED ")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Date & Time",
      "prop": "date_time"
    }
  })], 1), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_vm.tableData.data ? _c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.tableData.per_page || 0,
      "total": _vm.tableData.total || 0
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };