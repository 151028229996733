import reportsTable from "./reportsTable.vue";
import smsReportsChart from "./smsReportsChart.vue";
export default {
  components: {
    reportsTable,
    smsReportsChart
  },
  data() {
    return {
      formData: {
        from: "",
        to: "",
        domain: null,
        sender_id: ""
      },
      date_item: [],
      search_loading: false,
      tableLoading: false
    };
  },
  computed: {
    domains() {
      return this.$store.state.admin.reseller.resellerDomainNames;
    }
  },
  methods: {
    searchResellerHistory() {
      // check form validation
      if (!this.date_item.length) {
        this.$message.error("Please select date range.");
        return;
      }
      if (!this.formData.domain) {
        this.$message.error("Please select domain.");
        return;
      }
      this.search_loading = true;
      this.tableLoading = true;
      this.formData.from = this.date_item[0].toISOString().replace(/T/, " ").replace(/\..+/, "").split(" ")[0];
      this.formData.to = this.date_item[1].toISOString().replace(/T/, " ").replace(/\..+/, "").split(" ")[0];
      this.$store.dispatch("admin/reseller/getResellerSMSReports", this.formData).then(() => {
        this.search_loading = false;
      }).catch(() => {
        this.$message.error("Something went wrong, please try again later.");
      }).finally(() => {
        this.search_loading = false;
        this.tableLoading = false;
      });
    },
    handleUpdatePage(val) {
      this.tableLoading = true;
      this.loading = true;
      this.$store.dispatch("admin/reseller/getResellerSMSReports", {
        ...this.formData,
        page: val
      }).catch(() => {
        this.$message.error("Something went wrong, please try again later.");
      }).finally(() => {
        this.tableLoading = false;
        this.loading = false;
      });
    }
  }
};